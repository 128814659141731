<template>
    <div class="main">
        <van-cell-group :border="false">
            <van-cell center>
                <template #title>
                    <van-field v-model="objData.nsrsbh" placeholder="纳税人识别号" readonly label="税号" />
                </template>
            </van-cell>
            <van-cell center>
                <template #title>
                    <van-field v-model="objData.nsrmc" placeholder="纳税人名称" readonly label="名称"/>
                </template>
            </van-cell>
            <van-cell center>
              <template #title>
                <van-field v-model="objData.scjydz" placeholder="生产经营地址" readonly label="地址"/>
              </template>
            </van-cell>

            <van-cell center is-link @click="swzq.zqshow=true" v-if="swzq.yin">
                <template #title>
                    <van-field readonly v-model="swzq.zqRate" placeholder="请选择征期" label="征期"/>
                </template>
            </van-cell>
            <van-cell center is-link @click="swzq.nianshow=true" v-if="swzq.isShow">
                <template #title>
                    <van-field readonly v-model="swzq.nianRate" placeholder="请选择年度" label=" "/>
                </template>
            </van-cell>
            <van-cell center is-link @click="swzq.yjshow=true" >
                <template #title>
                    <van-field readonly v-model="swzq.yjRate" placeholder="请选择季（月）度" label="征期 "/>
                </template>
            </van-cell>

            <van-cell center>
                <template #title>
                    <van-field v-model="objData.semail" placeholder="请输入邮箱" type="email" label="邮箱"/>
                </template>
            </van-cell>

        </van-cell-group>
        <van-cell-group title=" " :border="false">
            <van-cell center>
                <van-button type="primary" size="large"  @click="save()">体 检</van-button>
            </van-cell>
        </van-cell-group>

        <van-popup v-model:show="swzq.zqshow" round position="bottom">
            <van-picker
                show-toolbar
                :columns="swzq.zqcolumns"
                @cancel="swzq.zqshow = false"
                @confirm="onConfirmZq"
            />
        </van-popup>
        <van-popup v-model:show="swzq.nianshow" round position="bottom">
            <van-picker
                show-toolbar
                :columns="swzq.niancolumns"
                @cancel="swzq.nianshow = false"
                @confirm="onConfirmNian"
            />
        </van-popup>
        <van-popup v-model:show="swzq.yjshow" round position="bottom">
            <van-picker
                show-toolbar
                :columns="swzq.yjcolumns"
                @cancel="swzq.yjshow = false"
                @confirm="onConfirmYj"
            />
        </van-popup>
        
    </div>

    <!-- 遮罩loading -->
    <van-overlay :show="showload" @click="showload = false" >
        <van-loading type="spinner" color="#fff" size="50px"  style="display: flex;align-items: center;justify-content: center;height: 100%;" />
    </van-overlay>
</template>

<script>
import {reactive, getCurrentInstance, ref} from 'vue';
import {Toast} from 'vant';
import Request from '../../api/swtj'
export default {
    name: "swtjAdd",
    setup(){
        const { proxy } = getCurrentInstance();
        console.log(proxy.$root.$route.query); 
        console.log(proxy.$root.$route.params);
        const objData = reactive({
            nsrsbh:"",
            nsrmc:"",
            scjydz:"",
            semail:"",
            nsrlx:""
        });
        const qxObj = reactive({
            jds:{},
            yds:{},
            nds:[],

        });
        Request.safk_nsrxx({}).then(res=>{

            if (res.returnCode === "00") {
                console.log('res=>',res.rows);
                objData.nsrsbh = res.rows.nsrsbh;
                objData.nsrmc = res.rows.nsrmc;
                objData.scjydz = res.rows.scjydz;
                objData.nsrlx = res.rows.nsrlx; //新增纳税类型
                console.log(res.rows.nsrlx);
                
                // if(res.rows.nsrlx==""){      //当前只用年度其他的选项暂时屏蔽，后期如有需要修改可放开
                //     swzq.zqcolumns=["年度","季度"];
                //     swzq.zqRate = "年度";
                // }else if(res.rows.nsrlx == "xgm"){
                //     swzq.zqcolumns=["季度"];
                //     swzq.zqRate = "季度";
                // }else if(res.rows.nsrlx=="ybnsr"){
                //     swzq.zqcolumns=["年度"];
                //     swzq.zqRate = "年度";
                // }

                Request.safk_zq({}).then(res=>{
                    console.log(res.rows);
                    qxObj.nds = res.rows.nds;
                    qxObj.jds = res.rows.jds;
                    qxObj.yds = res.rows.yds;
                    console.log(qxObj.nds)
                    tianFun('one');
                    
                }).catch(err=>{
                    console.log('err=>',err);
                });
                
            }else{  
                Toast(res.returnMessage);
            }
        }).catch(err=>{
            console.log('err=>',err);
        });

        

        const save = () =>{
            console.log(swzq.zqval);
            if(swzq.zqval==""){
                Toast("请先选择征期");
                return
            }
            showload.value = true;
            Request.safk_fxjc({semail: objData.semail,zq:swzq.zqval}).then(res=>{
                showload.value = false;
                if (res.returnCode === "00"){
                    Toast(res.returnMessage);
                    proxy.$root.$router.go(-1);
                }else{
                    Toast(res.returnMessage);
                }
            });
        };

        const showload = ref(false);
        const swzq = reactive({
            zqshow:false,
            zqcolumns:["年度"],
            zqRate:"年度",
            nianshow:false,
            niancolumns:[],
            nianRate:"",
            yjshow:false,
            yjcolumns:[],
            yjRate:"",
            isShow:false, //年度时隐藏
            zqval:"",
            yin:false
        });
        const onConfirmZq = (value, index)=> {
            console.log(value+"-"+index);
            swzq.zqRate = value;
            swzq.zqshow = false;
            tianFun('one');
        };
        const onConfirmNian = (value, index)=> {
            console.log(value+"-"+index);
            swzq.nianRate = value;
            swzq.nianshow = false;
            tianFun();
        };
        const onConfirmYj = (value, index)=> {
            console.log(value+"-"+index);
            swzq.yjRate = value;
            swzq.yjshow = false;
            if(swzq.zqRate =="年度"){
                for(var i= 0;i<qxObj.nds.length;i++){
                    if(value == qxObj.nds[i].key){
                        swzq.zqval = qxObj.nds[i].val;
                    }
                }
            // }else if(swzq.zqRate =="季度"){
            //     for(var j= 0;j<qxObj.jds[swzq.nianRate].length;j++){
            //       if(value == qxObj.jds[swzq.nianRate][j].key){
            //         swzq.zqval = qxObj.jds[swzq.nianRate][j].val;
            //       }
            //     }
            // }else if(swzq.zqRate =="月度"){
            //     for(var y= 0;y<qxObj.jds[swzq.nianRate].length;y++){
            //       if(value == qxObj.jds[swzq.nianRate][y].key){
            //         swzq.zqval = qxObj.jds[swzq.nianRate][y].val;
            //       }
            //     }
            }
        };
        const tianFun = (isOne)=> {
            console.log(swzq.zqRate);
            console.log(qxObj);

            if(swzq.zqRate =="年度"){
                swzq.isShow =false;
                swzq.yjcolumns=[];
                for(var i =0;i<qxObj.nds.length;i++){
                    swzq.yjcolumns.push(qxObj.nds[i].key);
                    if(isOne == "one"){
                        swzq.yjRate = qxObj.nds[0].key;
                        swzq.zqval = qxObj.nds[0].val;
                    }
                }
                
            // }else if(swzq.zqRate =="季度"){
            //     swzq.isShow =true;
            //     swzq.niancolumns=[];
                
            //     for(var key in qxObj.jds){
            //         swzq.niancolumns.push(key);
                    
            //     }
            //     if(isOne == "one"){
            //         swzq.nianRate = swzq.niancolumns[0];
            //     }
            //     // console.log(swzq.nianRate);
            //     // console.log(qxObj.jds[swzq.nianRate])
            //     // console.log(qxObj.jds[swzq.nianRate].length)
            //     swzq.yjcolumns=[];
            //     for(var j =0;j< qxObj.jds[swzq.nianRate].length; j++){
            //         swzq.yjcolumns.push(qxObj.jds[swzq.nianRate][j].key);
            //         if(isOne == "one"){
            //             swzq.zqval = qxObj.jds[swzq.nianRate][0].val;
            //             swzq.yjRate = qxObj.jds[swzq.nianRate][0].key;
            //         }else{
            //             swzq.yjRate ='';
            //             swzq.zqval ='';
            //         }
            //     }
              
            // }else if(swzq.zqRate =="月度"){
            //     swzq.isShow =true;
            //     swzq.niancolumns=[];

            //     for(var keys in qxObj.yds){
            //         swzq.niancolumns.push(keys);
            //     }
            //     if(isOne == "one"){
            //         swzq.nianRate = swzq.niancolumns[0];
            //     }
            //     swzq.yjcolumns=[];
            //     for(var y =0;y< qxObj.yds[swzq.nianRate].length; y++){
            //         swzq.yjcolumns.push(qxObj.yds[swzq.nianRate][y].key);
            //         if(isOne == "one"){
            //             swzq.zqval = qxObj.yds[swzq.nianRate][0].val;
            //             swzq.yjRate = qxObj.yds[swzq.nianRate][0].key;
            //         }else{
            //             swzq.yjRate ='';
            //             swzq.zqval ='';
            //         }
            //     }
              
            }
        };
        return{
            objData,
            showload,
            save,
            swzq,
            onConfirmZq,
            onConfirmNian,
            onConfirmYj,
            qxObj,
            tianFun
        };
    }
}
</script>

<style scoped>
    .main {
        min-height: 100vh;
        /* position: relative; */
    }
    .van-field__label {
      width:50px !important;
    }
</style>